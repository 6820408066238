










































































































































































































































import LookUp from '@/components/lookup/Index.vue'
import { authPermissions, genericErrorMessage } from '@/constants'
import { EventBus, ON_SEARCH_PAGE_CREATE, ON_SEARCH_PAGE_DELETE, ON_SEARCH_PAGE_EDIT, TRIGGER_SEARCH } from '@/eventbus'
import { clientService, userService } from '@/services'
import { Role } from '@/types/UserServiceType'
import { BaseSearchOption } from '@/types/utilTypes'
import { cloneDeep, debounce } from 'lodash'
import Vue from 'vue'


function generateInitialUpdateForm() {
    return {
        user: {
            username: '',
            firstName: '',
            lastName: '',
            isActive: false,
            roles: {} as Record<string, boolean>,
        },
        dialog: false,
        error: '',
        success: false,
        loading: false,
    }
}

function generateInitialUserRemoveForm(){
    return {
        loading: false,
        success: false,
        error: false,
        dialog: false,
        user: { username: ''}
    }
}

export default Vue.extend({
    name: 'User',
    components: {
        LookUp
    },
    async created() {
        this.debouncedGetUsersNotInClient = debounce(this.getUsersNotInClient.bind(this), 700)
    },
    async mounted() {
        await this.getAvailableRoles()
        EventBus.$on(ON_SEARCH_PAGE_EDIT, (pageName:string, payload: any) => {
            if(pageName === this.options.name) { 
                this.onUserEdit(payload)
            }
        })

        EventBus.$on(ON_SEARCH_PAGE_CREATE, (pageName: string) => {
            if(pageName === this.options.name) { 
                this.addUsersForm.addUsersDialog = true
                this.debouncedGetUsersNotInClient(this.addUsersTableSearch)
            }
        })

        EventBus.$on(ON_SEARCH_PAGE_DELETE, (pageName: string, payload: any) => {
            if(pageName === this.options.name) { 
                this.removeUserInClientForm = generateInitialUserRemoveForm()
                this.removeUserInClientForm.user = payload
                this.removeUserInClientForm.dialog = true
            }
        })
    },
    destroyed() {
        EventBus.$off()
    },
    data(){
        return {
            options:{
                crudConfig: {
                    isDeleteable:  this.$store.getters.user.permissions[authPermissions.canManageClients],
                    isUpdateable: true,
                    isCreateable: this.$store.getters.user.permissions[authPermissions.canManageClients],
                    createPlaceHolderOverride: 'Add Users to this Client'
                },
                name: 'users',
                api: '/User/GetUsers',
                exportAPI: {
                    api: '/User/ExportUsers',
                    requiredPermissions: [authPermissions.canExportAll]
                },
                pivotTableAPI: {
                    api:'',
                    params: {
                        source: ''
                    }},
                fields:[
                    {
                        'name': 'username',
                        'alias': 'Username',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':0,
                    },
                    {
                        'name': 'email',
                        'alias': 'Email',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':1,
                    },
                    {
                        'name': 'firstName',
                        'alias': 'First Name',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':2
                    },
                    {
                        'name': 'lastName',
                        'alias': 'Last Name',
                        'defaultOperator': 'exact match',
                        'type': 'string',
                        'dataTableOrder':3
                    },
                    {
                        'name': 'isActive',
                        'alias': 'Active',
                        'defaultOperator': 'exact match',
                        'method':'formatBoolean',
                        'type': 'string',
                        'dataTableOrder':4
                    },
                    // {
                    //     'name': 'domain',
                    //     'alias': 'Domain',
                    //     'defaultOperator': 'exact match',
                    //     'type': 'string',
                    //     'dataTableOrder':1
                    // },
                    {
                        'name': 'roles',
                        'alias': 'Roles',
                        'defaultOperator': 'contains',
                        'type': 'string',
                        'dataTableOrder':5,
                        'method': 'quickFilterList',
                        'sortable': false
                    },
                    // {
                    //     'name': 'permissions',
                    //     'alias': 'Permissions',
                    //     'defaultOperator': 'exact match',
                    //     'type': 'string',
                    //     'dataTableOrder':6
                    // },
                    // {
                    //     'name': 'lastLoginDate',
                    //     'alias': 'Last Login',
                    //     'defaultOperator': 'exact match',
                    //     'type': 'string',
                    //     'dataTableOrder':7
                    // },
                    // {
                    //     'name': 'createdBy',
                    //     'alias': 'Created By',
                    //     'defaultOperator': 'exact match',
                    //     'type': 'string',
                    //     'dataTableOrder':8
                    // },
                ],
                keywordSearchPlaceholder: 'Enter Username or First Name or Last Name'
            },
            availableRoles: [] as Role[],
            updateForm: generateInitialUpdateForm(),
            addUsersForm: {
                error:false,
                success: false,
                addUsersDialog: false,
                users: [] as any,
                headers: [
                    { text: 'Username', value: 'username'}, 
                    { text:'Email', value: 'email'},
                    { text: 'First Name', value: 'firstName'},
                    { text: 'Last Name', value: 'lastName'},
                    { text: 'Actions', value: 'actions', sortable: false },
                ],
                totalRecords: 0,
                addLoading: false,
                fetchLoading: false
            },
            removeUserInClientForm: generateInitialUserRemoveForm() ,
            // Separated for performance
            addUserSortBy: '',
            addUserSortDesc: false,
            addUsersTableSearch: {
                keywords: '',
                pageIndex: 1,
                pageSize: 10,
                order: '',
                timeZoneOffsetInMinutes: -420
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            debouncedGetUsersNotInClient: debounce((_search: BaseSearchOption) => {return}, 500),
            genericErrorMessage
        }
    },
    methods: {
        generateRolesDict() {
            return this.availableRoles.reduce<Record<string,boolean>>((prev, curr) => {
                prev[curr.id] = false
                return prev
            }, {})
        },
        async onUserEdit(item: any) {
            this.onUpdateCancel()
            this.updateForm.user = {...item, roles: this.updateForm.user.roles}
            const userRoles = await userService.getUserRoles(item.username)
            userRoles.forEach(uRole => this.updateForm.user.roles[uRole.id] = true)
            this.updateForm.dialog = true
        },
        onUpdateCancel() {
            this.updateForm = generateInitialUpdateForm()
            this.updateForm.user.roles = this.generateRolesDict()
        },
        onRoleUpdate(value: boolean, role: Role) {
            this.updateForm.user.roles[role.id] = value
        },
        async onUpdateSave() {
            this.updateForm.error = ''
            this.updateForm.success = false
            this.updateForm.loading = true

            const temp = cloneDeep(this.updateForm.user)
            
            try {
                await userService.updateUser({...temp , roles: Object.keys(temp.roles).filter(o=>temp.roles[o])})
                this.updateForm.success = true
            } catch (error) {
                this.updateForm.error = genericErrorMessage
            } finally{ 
                this.updateForm.loading = false
            }
            EventBus.$emit(TRIGGER_SEARCH)
        },
        async getAvailableRoles() {
            this.availableRoles = await userService.getAvailableRoles()
            this.updateForm.user.roles = this.generateRolesDict()
        },
        async getUsersNotInClient(search: BaseSearchOption) {
            this.addUsersForm.fetchLoading = true
            try {
                const data = await clientService.getUsersNotInClient(search)
                this.addUsersForm.users = data.records
                this.addUsersForm.totalRecords = data.totalRecords
            } catch(e) {
                this.addUsersForm.users = []
                this.addUsersForm.totalRecords = 0
            }
            this.addUsersForm.fetchLoading = false
        },
        async onAddUserToClient(userId: string) {
            this.addUsersForm.error = false
            this.addUsersForm.success = false
            this.addUsersForm.addLoading = true

            try {
                await clientService.addUsersToClient({ users: [userId] })
                await this.debouncedGetUsersNotInClient(this.addUsersTableSearch)
                this.addUsersForm.success = true
                
                EventBus.$emit(TRIGGER_SEARCH)
            }  catch(_) {
                this.addUsersForm.error = true
            }

            this.addUsersForm.addLoading = false
        },
        async onRemoveUsersInClient(userId: string) {
            this.removeUserInClientForm.error = false
            this.removeUserInClientForm.success = false
            this.removeUserInClientForm.loading = true

            try {
                await clientService.removeUsersInClient({ users: [userId]})
                this.removeUserInClientForm.success = true
                EventBus.$emit(TRIGGER_SEARCH)
            } catch(_) {
                this.removeUserInClientForm.error = true
            }

            this.removeUserInClientForm.loading = false
        },
        onRemoveUserCancel() {
            this.removeUserInClientForm = generateInitialUserRemoveForm()
        },
        resetUserAddForm() {
            this.addUsersForm.addLoading = false
            this.addUsersForm.success = false
            this.addUsersForm.error = false
        }
    },
    computed: {
        groupedRoles(): any[] {
            const groupedItems = [] as any[]
            const size = 3
            for (let i = 0; i < this.availableRoles.length; i += size) {
                groupedItems.push(this.availableRoles.slice(i, i+size))
            }
            return groupedItems
        },
        newUserTableOrder(): string {
            const alias = this.addUsersForm.headers.find(x => x.value == this.addUserSortBy)?.text || ''
            return `${alias} ${this.addUserSortDesc ? 'desc' : ''}`.trim()
        }
    },
    watch: {
        newUserTableOrder(newValue) {
            this.addUsersTableSearch.order = newValue
        },
        addUsersTableSearch:{
            async handler(newValue){
                await this.debouncedGetUsersNotInClient(newValue)
            },
            deep:true,
        }
        
    },
})
